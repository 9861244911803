


























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Service } from '@/repository/kontent/stores/serviceStore';
import I18n from '@/i18n/index';

@Component
export default class ServiceItem extends Vue {
  @Prop(Object) service!: Service;
  private i18n = I18n;

  private get title() {
    return this.service?.title.value;
  }
  private get subtitle() {
    return this.service?.subtitle.value;
  }
  private get summary() {
    return this.service?.summary.value;
  }
  private get thumbnail() {
    return this.service?.thumbnail.value[0]?.url;
  }
}
