







import { Component, Vue, Prop } from 'vue-property-decorator';
import { Service } from '@/repository/kontent/stores/serviceStore';
import { Linkset } from '@/repository/kontent/stores/sitemapStore';
import ServiceItem from './ServiceItem.vue';

@Component({
  components: {
    ServiceItem,
  },
})
export default class ServiceList extends Vue {
  @Prop(Object) serviceList!: Linkset;

  private get id() {
    return this.serviceList?.toplink.value[0].url.value;
  }
  private get title() {
    return this.serviceList?.toplink.value[0].title.value;
  }
  private get services() {
    return this.serviceList?.list.value as Array<Service>;
  }

  async mounted() {
    this.jumpToHash();
  }

  async jumpToHash() {
    if (!this.$route.hash) return;
    const elem = document.querySelector(this.$route.hash ?? '');
    if (elem) {
      const top = elem.getBoundingClientRect().top;
      const newY = window.scrollY + top;
      window.scrollTo(0, newY);
    }
  }
}
