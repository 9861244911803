






















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Service } from '@/repository/kontent/stores/serviceStore';
import { LinksetStore, Linkset, Link } from '@/repository/kontent/stores/sitemapStore';
import { reactive } from '@vue/composition-api';
import ServiceList from './ServiceList.vue';
import LocalNav from './LocalNav.vue';
import I18n, { Languages } from '@/i18n/index';

@Component({
  components: {
    ServiceList,
    LocalNav,
  },
})
export default class Index extends Vue {
  private i18n = I18n;

  @Watch('$route')
  onRouteChanged(): void {
    this.getServiceList();
  }

  private async created() {
    this.getServiceList();
  }

  private serviceLink = reactive(new LinksetStore());

  private targetLang = this.$route.params.language as Languages;

  private get serviceGroup() {
    return this.serviceLink.list[0]?.list.value as Array<Linkset>;
  }

  private get serviceGroupLink() {
    return this.serviceLink.list[0]?.list.value.map((x) => x.toplink.value[0] as Link);
  }

  private getServiceList() {
    this.serviceLink.initQuery();
    this.serviceLink.setFilter('system.name', 'サービス');
    this.serviceLink.setDepth(2);
    this.serviceLink.restore();
  }

  private get serviceList() {
    const result = this.serviceLink.list[0]?.list.value
      .map((y) => {
        return y.list?.value;
      })
      .reduce((a, b) => {
        return a.concat(b);
      }) as Array<Service>;
    return [...Array.from(new Set(result))];
  }
}
